import { EnvironmentsEnums } from '@bootstrap/constants/environments';
interface IRequestData {
  query: string;
  variables?: { [key: string]: any };
}

const getProducts = async ({ token, environment }: { token: string; environment: string }) => {
  const makeResponse = async (url: string, data: IRequestData, token = '') => {
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    } catch (error) {
      console.error('Error making request:', error);
      throw error;
    }
  };

  const productsQuery = 'query Products { products }';

  try {
    const productsData = await makeResponse(getGraphqlAPIUrl(environment), { query: productsQuery }, token);
    if (!productsData || productsData.errors) {
      const errorMessage = productsData.errors ? productsData.errors[0].message : 'Unknown error';
      return { products: null, error: errorMessage };
    }
    const products = productsData.data.products;
    return { products, error: null };
  } catch (error) {
    return { products: null, error: error.message };
  }
};

// converting clientId to product name based on BE mapping
const productMapping: { [key: string]: string[] } = {
  'factoring-v2-frontend': ['factoring'],
  connect: ['connect'],
  'pay-for-platforms': ['pay-for-platforms'],
};

// converting clientId to product name for translation
export const clientIdToProductName = (clientId: string): string => {
  const mapping: { [key: string]: string } = {
    'factoring-v2-frontend': 'factoring',
    connect: 'connect',
    'pay-for-platforms': 'pfp',
  };

  return mapping[clientId] || clientId;
};

export const isUserAccessAllowed = async ({
  token,
  clientId,
  environment,
}: {
  token: string;
  clientId: string;
  environment: string;
}): Promise<boolean> => {
  // Return true immediately if the application is Tars
  if (clientId === 'tars-fe') {
    return true;
  }

  try {
    const result = await getProducts({ token, environment });

    if (result.products) {
      const mappedProducts = productMapping[clientId] || [];

      return result.products.some((product: string) => {
        return mappedProducts.includes(product);
      });
    }
  } catch (error) {
    console.error('Error checking user access:', error);
    // Remove this line after the onboarding API is fixed
    return true;
  }
  return false;
};

export const getAccountApplicationListUrl = (env: string): string => {
  const environment = env.toLowerCase();

  switch (environment) {
    case EnvironmentsEnums.FIAB:
      return 'http://localhost:8020/auth/realms/MyFinqle/account/applications';
    case EnvironmentsEnums.DEVELOPMENT:
    case EnvironmentsEnums.LOCAL:
    case EnvironmentsEnums.PRE_PROD:
      return 'https://my.finqle.dev/auth/realms/MyFinqle/account/applications';
    case EnvironmentsEnums.PRODUCTION:
      return 'https://my.finqle.com/auth/realms/MyFinqle/account/applications';
    case EnvironmentsEnums.SANDBOX:
      return 'https://auth.sandbox.finqle.com/auth/realms/MyFinqle/account/applications';
    default:
      throw new Error(`Unknown environment: ${environment}`);
  }
};

const getGraphqlAPIUrl = (env: string): string => {
  const environment = env.toLowerCase();

  switch (environment) {
    case EnvironmentsEnums.DEVELOPMENT:
    case EnvironmentsEnums.LOCAL:
      return 'https://onboarding.finqle.dev/public/v2';
    case EnvironmentsEnums.PRE_PROD:
      return 'https://onboarding.pre-prod.finqle.dev/public/v2';
    case EnvironmentsEnums.PRODUCTION:
      return 'https://onboarding.finqle.com/public/v2';
    case EnvironmentsEnums.SANDBOX:
      return 'https://onboarding.sandbox.finqle.com/public/v2';
    case EnvironmentsEnums.FIAB:
      return 'http://localhost:8003/public/v2';
    default:
      throw new Error(`Unknown environment: ${environment}`);
  }
};
